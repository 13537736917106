@import "bootstrap/scss/bootstrap.scss";
@import "./app/shared/scss/_variable.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-ExtraBold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-Italic.ttf);
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-Light.ttf);
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url(./assets/OpenSans/static/OpenSans/OpenSans-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

body {
  background-color: transparent !important;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  color: var(--calenso-black);
  font-size: 12px;
}

.grecaptcha-badge {
  display: none !important;
}

.btn-success {
  background-color: var(--calenso-green);
  font-size: 13px;
  color: var(--calenso-white);
  border: 1px solid var(--calenso-green);
}

.card-body {
  font-size: 13px;
}

.rating {
  direction: rtl;
  unicode-bidi: bidi-override;
  color: var(--calenso-yellow);

  input {
    display: none;
  }
}

a {
  color: var(--calenso-violet);
  font-size: 12px;

  &:hover {
    color: var(--calenso-violet);
    text-decoration: none;
  }
}

.reseller-form-input {
  height: 45px;
  color: var(--calenso-primary-color);
  background-color: var(--calenso-white);
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 3px;
  font-family: inherit;
  width: 100%;
  margin: .5rem 0 0.1rem 0;
  padding: .5rem .625rem .5625rem;
  outline: none;
  border: 1px solid var(--calenso-primary-color);
}

.calio-reseller-label {
  color: var(--calenso-black);
  font-size: 14px;
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
}

.calio-pc-reseller-container {
  padding-top: 10px;
  background-color: transparent;
}

.calio-pc-button-container {
  text-align: center;
}

.signin-button {
  max-width: 100%;
  padding: 15px;
  opacity: 1;
  border: none;
  border-radius: 0.25rem !important;
}

.signin-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.calio-pc-reseller-button {
  border-color: var(--calenso-violet);
  background-color: var(--calenso-violet);
  padding: 12px;
  font-size: 16px !important;
  color: var(--calenso-white);
  border-radius: 0.25rem !important;
  width: 100%;
  outline: 0;
  height: 50px;

  @media screen and (max-width: 450px) {
    padding: 5px;
  }
}

.forgot-password {
  text-align: center;
  font-size: 14px;
}

.calio-pc-reseller-invalid-feedback {
  color: var(--calenso-red) !important;
  font-size: 12px !important;
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 25px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  margin: 0;
  border: 3px solid var(--calenso-white);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--calenso-white) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

a {
  color: var(--calenso-violet);
}

.dropdown-menu {
  border-radius: 8px !important;
  box-shadow: 0 0 64px -26px rgba(0, 0, 0, .2) !important;
}