:root {
  --calenso-violet: #5555FF;
  --calenso-primary-color: #3A4A66;
  --calenso-white: #FFFFFF;
  --calenso-black: #000000;
  --calenso-gray: GRAY;
  --calenso-red: #EF6565;
  --calenso-green: #97CC2E;
  --calenso-yellow: #FFCB3D;
  --calenso-light-red: #FFCBCB;
  --calenso-light-green: #C7EA71;
  --calenso-dark-green: #59791B;
  --calenso-light-sky-blue: #EBF1FB;
  --calenso-gray-200: #F1F1F1;
  --calenso-gray-300: #AAAAAA;
}